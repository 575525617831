<template>
  <v-card>
    <v-card-text>
      <div
        class="mb-6"
        v-for="(item, index) in items"
        :key="index"
        two-line
      >
        <div
          class="font-weight-bold"
        >
          {{ item.label|t }}
        </div>

        <div class="font-weight-light">
          <v-icon
            v-if="item.copyable"
            @click="$helper.copyText(item.text, $el)"
            left
          >
            fa fa-copy
          </v-icon>
          <span>{{ item.text }}</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    popupConfig: Object,
  },
  computed: {
    siteAdminLog() {
      return this.popupConfig.siteAdminLog
    },
    items() {
      return [
        {
          label: 'site_admin.data.id',
          copyable: true,
          text: this.siteAdminLog.site_admin_id,
        },
        {
          label: 'data.email',
          copyable: true,
          text: this.siteAdminLog.email,
        },
        {
          label: 'site_admin_log.data.action',
          text: this.$t(`site_admin_log.data.action.${this.siteAdminLog.action}`),
        },
        {
          label: 'data.ip',
          copyable: true,
          text: this.siteAdminLog.ip,
        },
        {
          label: 'site_admin_log.data.target',
          text: this.siteAdminLog.target,
        },
        {
          label: 'site_admin_log.data.target_ids',
          text: this.targetIds,
        },
        {
          label: 'data.created_at',
          text: this.siteAdminLog.created_at,
        },
      ]
    },
    targetIds() {
      if(!Array.isArray(this.siteAdminLog.target_ids)) return null
      return this.siteAdminLog.target_ids.join(', ')
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>